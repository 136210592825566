import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setLandingPageData(JsonData);
    const handleScroll = () => {
      console.log("Current scrollY:", window.scrollY); // Logging untuk memeriksa nilai scrollY
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      console.log("Button visibility:", showButton); // Logging untuk memeriksa perubahan showButton
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Navigation />
      <Header/>
      <About data={landingPageData.About} />
      <Team data={landingPageData.Team} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />
      {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          &#8679;
          <span className="tooltip">Back to Top</span>
        </button>
      )}
    </div>
  );
};

export default App;