import React, { useRef } from "react";
import { Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const Header = () => {
  const carouselRef = useRef(null);
  const carouselItems = [
    {
      src: '/img/Laboan-bajo/galery-4.jpeg',
      alt: 'First slide',
      title: 'WELCOME ASPERSINDO',
      subtitle: 'World Class Association of Security Printing Services.',
      description: 'Aspersindo provides a security printing platform, Synergize security printing companies in Indonesia within a framework of fair competition to become a trusted partner for security document solutions.'
    },
    // {
    //   src: '/img/Banner-expo.png',
    // },
  ];

  const handlePrev = () => carouselRef.current.prev();
  const handleNext = () => carouselRef.current.next();
  const settings = {
    dots: true,
  };
  return (
    <div id="home" className="carousel-container">
       <Carousel autoplay ref={carouselRef} {...settings}>
        {carouselItems.map((item, index) => (
          <div key={index} className="carousel-item">
            <img src={item.src} alt={item.alt} />
            {/* Render overlay only if the image is not Banner-expo */}
            {item.src !== '/img/Banner-expo.png' && (
              <div className="overlay">
                <div className="text-content">
                  <h5>{item.title}</h5>
                  <h1>{item.subtitle}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </Carousel>
      <Button
        shape="circle"
        icon={<LeftOutlined />}
        onClick={handlePrev}
        className="left-button"
      />
      <Button
        shape="circle"
        icon={<RightOutlined />}
        onClick={handleNext}
        className="right-button"
      />
    </div>
  );
};

export default Header;
