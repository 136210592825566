import React, { useState, useEffect } from "react";

export const Navigation = (props) => {
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = ["home", "about", "team", "gallery", "Kontak"];

      if (scrollPosition === 0) {
        setActiveSection("home");
        return; // Early return if at the top
      }

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (scrollPosition >= offsetTop - 100 && scrollPosition < offsetTop + offsetHeight - 100) {
            setActiveSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleClick = (section) => {
    setActiveSection(section);
  
    if (section === "home") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
  
        // Menambahkan offset jika bukan "home"
        setTimeout(() => {
          const offset = -80; // Sesuaikan dengan tinggi navbar
          window.scrollBy(0, offset);
        }, 300); // Sesuaikan delay agar sinkron dengan scrollIntoView
      }
    }
  };
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFirstVisible, setIsFirstVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstVisible((prev) => !prev);
    }, 15000); // Change paragraph every 15 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
       <div className="breaking-news-container flex items-center">
      <h1 className="py-1 px-4 font-bold text-lg uppercase">Breaking News</h1>
      <p className={`text-sm py-1 pl-2  `}>
        Annual Meeting Aspersindo 2024 at Apurva Kempinski, Bali from October 31st until November 3rd 2024.
      </p>
    </div>

      <nav id="menu" className="navbar navbar-default navbar-bg navbar-fixed-top">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <img
            src="img/Logo-Aspersindo.svg"
            alt="Logo"
            className="logo"
          />
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className={activeSection === "home" ? "active" : ""}>
              <a href="#top" className="page-scroll" onClick={() => handleClick("home")}>
                Home
              </a>
            </li>
            <li className={activeSection === "about" ? "active" : ""}>
              <a href="#about" className="page-scroll" onClick={() => handleClick("about")}>
                About us
              </a>
            </li>
            <li className={activeSection === "team" ? "active" : ""}>
              <a href="#team" className="page-scroll" onClick={() => handleClick("team")}>
                Members
              </a>
            </li>
            <li className={activeSection === "gallery" ? "active" : ""}>
              <a href="#gallery" className="page-scroll" onClick={() => handleClick("gallery")}>
                Gallery
              </a>
            </li>
            <li className={activeSection === "Kontak" ? "active" : ""}>
              <a href="#Kontak" className="page-scroll" onClick={() => handleClick("Kontak")}>
                Contact US
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
